//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {NsIcon} from '@nuskin/ns-icon';
/**
 * Button behaves as a simple button element with provided styles. Passing an
 * `href` prop will use an `a` tag as the root element.
 *
 * Any other props and listeners supplied will be provided to the root element
 *
 * Questions: Should text ever wrap? Adding a type Text. Should small and large have different x paddings?
 */
export default {
  name: 'Button',
  components: {NsIcon},
  props: {
    /**
     * If true, the button will be disabled. (Has no effect when using `href`)
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * If true, the button will take up the full width of its container.
     */
    fullWidth: {
      type: Boolean,
      default: false
    },
    /**
     * The URL to link to when the button is clicked. If defined, an `a` element will be used as the root node.
     */
    href: {
      type: String,
      default: ''
    },
    /**
     * The size of the button. small is equivalent to the dense button styling.
     * @values large, small
     */
    size: {
      type: String,
      default: 'large'
    },
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     * @values primary, secondary, secondary-variant, error, inherit
     */
    color: {
      type: String,
      default: 'primary'
    },
    /**
     * The type to use.
     * @values solid, outline, ghost, text
     */
    type: {
      type: String,
      default: 'solid'
    },
    /**
     * Icon name of icon placed before the slot.
     */
    startIcon: {
      type: String,
      default: ''
    },
    /**
     * Icon name of icon placed after the slot.
     */
    endIcon: {
      type: String,
      default: ''
    }
  },
  computed: {
    baseClasses() {
      return {
        [this.$style.primary]: this.color === 'primary',
        [this.$style.secondary]: this.color === 'secondary',
        [this.$style.secondaryVariant]: this.color === 'secondary-variant',
        [this.$style.error]: this.color === 'error',
        [this.$style.inherit]: this.color === 'inherit',
        [this.$style.solid]: this.type === 'solid',
        [this.$style.outline]: this.type === 'outline',
        [this.$style.text]: this.type === 'text',
        [this.$style.large]: this.size === 'large',
        [this.$style.small]: this.size === 'small',
        [this.$style.fullWidth]: this.fullWidth,
        [this.$style.hover]: this.hover,
        [this.$style.pressed]: this.pressed
      };
    }
  }
};
