//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    };
  },
  // head() {
  //   return {
  //     title: `Catalog - ${this.title}`
  //   };
  // },
  computed: {
    title() {
      if (this.error.statusCode === 404) return this.pageNotFound;
      else if (this.error.statusCode === 500)
        return '500 error: ' + this.error.message;
      else return this.otherError;
    },
    isDev() {
      return !!this.$store.getters['nuskin/isDev'];
    },
    siteUrl() {
      return this.$store.getters['nuskin/siteUrl'] || '';
    }
  },
  watch: {
    siteUrl() {
      this.routeToSite();
    }
  },
  mounted() {
    if (this.isDev) {
      console.error(`[Catalog] (DEV) Error Page. ${this.title}.`, this.error);
    }
    this.routeToSite();
  },
  methods: {
    routeToSite() {
      if (!this.isDev && this.siteUrl) {
        // WARNING: $router.push will include the url at the end of the hashtag
        // this.$router.push(this.siteUrl);
        window.location = this.siteUrl;
      }
    }
  }
};
