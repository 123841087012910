import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=fa8a2418&"
import script from "./Carousel.vue?vue&type=script&lang=js&"
export * from "./Carousel.vue?vue&type=script&lang=js&"
import style0 from "./Carousel.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/gitlab-runner-data/builds/ns-am/product/apps/catalog/components/Button.vue').default,CarouselItems: require('/gitlab-runner-data/builds/ns-am/product/apps/catalog/components/CarouselItems.vue').default})
