
// This component wraps each child component in a div so the wrapper and the
// child can be styled separately.
export default {
  name: 'CarouselItems',
  functional: true,
  render(createElement, {children}) {
    if (!children || !children.length) {
      return createElement('div', {
        class: 'no-carousel-items'
      });
    }
    return children.map((child) =>
      createElement(
        'div',
        {
          class: 'carousel-item'
        },
        [child]
      )
    );
  }
};
