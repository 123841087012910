//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {NsIcon} from '@nuskin/ns-icon';
const DRAG_PERCENTAGE = 0.1;
const PEEK_RATIO = 4 / 5; // size of image width in window
const slowDrag = (movement) => -0.001 * (movement ^ 2) + 0.3 * movement;
export default {
  name: 'Carousel',
  components: {NsIcon},
  model: {
    prop: 'position',
    event: 'change'
  },
  props: {
    position: {
      type: Number,
      default: 0
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    gradientButtons: {
      type: Boolean,
      default: false
    },
    showNavigation: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    peek: {
      type: Boolean,
      default: false
    },
    spacing: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentIndex: 0,
      startTouchPosition: null,
      slideOffset: 0,
      dragOffset: 0,
      sliding: false,
      contentObserver: null,
      childrenLength: 0
    };
  },
  computed: {
    itemsStyle() {
      const allSlidesRatio = this.childrenLength * (this.peek ? PEEK_RATIO : 1);
      const space = this.spacing * (this.childrenLength - 1);
      return {
        gridTemplateColumns: `repeat(${this.childrenLength}, 1fr)`,
        columnGap: `${this.spacing}px`,
        left: this.slideOffset + this.dragOffset + 'px',
        width: `calc(100% * ${allSlidesRatio} + ${space}px)`,
        transition: this.sliding ? 'left 0.3s ease-in-out' : ''
      };
    },
    carouselStyle() {
      return {
        paddingTop: `min(${this.peek ? PEEK_RATIO * 100 : 100}%, 100vh)`
      };
    },
    leftDisabled() {
      return !this.loop && this.currentIndex === 0;
    },
    rightDisabled() {
      return !this.loop && this.currentIndex === this.childrenLength - 1;
    }
  },
  watch: {
    position(index) {
      this.currentIndex = index;
    },
    currentIndex(index) {
      this.slideToIndex(index);
      this.$emit('change', index);
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.childrenLength =
      (this.$refs.items && this.$refs.items.childElementCount) || 0;
    this.contentObserver = new MutationObserver(() => {
      this.childrenLength = this.$refs.items.childElementCount;
    });
    this.contentObserver.observe(this.$refs.items, {childList: true});
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.contentObserver.disconnect();
  },
  methods: {
    // this method is the end of the line, do not trigger anything in this method
    slideToIndex(index, noAnimation) {
      this.sliding = !noAnimation;
      const carouselWindowWidth = this.$refs.carousel.offsetWidth;
      const slideWidth = carouselWindowWidth * (this.peek ? PEEK_RATIO : 1);

      if (this.peek && index === this.childrenLength - 1) {
        const space = this.spacing * (this.childrenLength - 1);
        const allItemsLength = this.childrenLength * slideWidth + space;
        this.slideOffset = -(allItemsLength - carouselWindowWidth);
      } else {
        this.slideOffset = -(index * slideWidth + index * this.spacing);
      }

      setTimeout(() => {
        this.sliding = false;
      }, 300);
    },
    goToPrevImage() {
      let newIndex = this.currentIndex - 1;

      if (newIndex < 0) {
        newIndex = this.childrenLength - 1;
      }

      this.currentIndex = newIndex;
    },
    goToNextImage() {
      let newIndex = this.currentIndex + 1;

      if (newIndex >= this.childrenLength) {
        newIndex = 0;
      }

      this.currentIndex = newIndex;
    },
    handleTouchMove(event) {
      // if moving horizontally, don't allow vertical scroll or horizontal page shift
      if (this.dragOffset) {
        event.preventDefault();
      }

      const touch = event.targetTouches[0];
      const touchPosition = touch.pageX;

      if (this.startTouchPosition !== null) {
        const touchMovement = touchPosition - this.startTouchPosition;

        if (this.leftDisabled && touchMovement > 0) {
          // if drag is disabled, do a slow drag animation
          this.dragOffset = slowDrag(touchMovement);
        } else if (this.rightDisabled && touchMovement < 0) {
          // if drag is disabled, do a slow drag animation
          this.dragOffset = slowDrag(touchMovement);
        } else {
          this.dragOffset = touchMovement;
        }
      } else {
        this.startTouchPosition = touchPosition;
      }
    },
    handleTouchEnd(event) {
      if (this.startTouchPosition !== null) {
        event.preventDefault();
        event.stopPropagation();
        const carouselWindowWidth = this.$refs.carousel.offsetWidth;
        this.startTouchPosition = null;

        if (
          this.dragOffset > carouselWindowWidth * DRAG_PERCENTAGE &&
          !this.leftDisabled
        ) {
          this.dragOffset = 0;
          this.goToPrevImage();
        } else if (
          this.dragOffset < -carouselWindowWidth * DRAG_PERCENTAGE &&
          !this.rightDisabled
        ) {
          this.dragOffset = 0;
          this.goToNextImage();
        } else {
          this.sliding = true;
          this.dragOffset = 0;
          setTimeout(() => {
            this.sliding = false;
          }, 300);
        }
      }
    },
    handleResize() {
      this.slideToIndex(this.currentIndex, true);
    }
  }
};
