export const state = () => ({
  isLoggedIn: false,
  user: {},
  userId: '',
  isDistributor: false,
  isPreferredCustomer: false
});

export const mutations = {
  set(state, {user, isLoggedIn}) {
    state.isLoggedIn = isLoggedIn;
    if (isLoggedIn) {
      state.user = user;
      state.userId = user.id;
      state.isDistributor = !!user.isDistributor();
      state.isPreferredCustomer = !!user.isPreferredCustomer();
    } else {
      state.user = {};
      state.userId = '';
      state.isDistributor = false;
      state.isPreferredCustomer = false;
    }
  }
};
