//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {NsHeader, NsFooter} from '@nuskin/app-components';
import {events} from '@nuskin/ns-util';
import {NsToast} from '@nuskin/design-components';
import {CartService} from '@nuskin/ns-shop';

const ToastType = {
  Default: '',
  Success: 'success',
  Warning: 'warning',
  Error: 'error'
};

export default {
  name: 'App',
  components: {
    NsHeader,
    NsToast,
    NsFooter
  },
  data() {
    return {
      mySiteHeaderProps: {},
      mySiteFooterProps: {},
      toastTrigger: false,
      toastType: ToastType.Default,
      toastMessage: '',
      toastButtonText: '',
      toastAction: () => {},
      overlaySearch: {
        zIndex: 100001, // just one higher then search results UI in static app catalog view
        position: 'relative'
      },
      hideUnderSearch: {
        display: 'none'
      }
    };
  },
  computed: {
    ready() {
      return !!this.$store.state.nuskin.ready;
    }
  },
  mounted() {
    this.addListeners();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    showCartToast({amount, productName, equinoxQtyOverride}) {
      this.toastType = ToastType.Success;
      this.toastMessage = this.$t('cartToast.addedToCart')
        .replace('{amount}', amount)
        .replace('{productName}', productName)
        .trim();
      this.toastButtonText = this.$t('cartToast.viewCart')
        .replace('{amount}', equinoxQtyOverride ? '' : CartService.getItemCnt())
        .replace('()', equinoxQtyOverride && '')
        .trim();
      this.toastAction = () =>
        CartService.goToCartPage({isMySite: this.isMySite});
      this.toastTrigger = !this.toastTrigger;
      setTimeout(() => {
        this.toastTrigger = false;
      }, 100);
    },

    showFailedCartToast({productName}) {
      this.toastType = ToastType.Warning;
      this.toastMessage = this.$t('cartToast.failedAddToCart')
        .replace('{productName}', productName)
        .trim();
      this.toastButtonText = '';
      this.toastAction = () => {};
      this.toastTrigger = !this.toastTrigger;
      setTimeout(() => {
        this.toastTrigger = false;
      }, 100);
    },

    addListeners() {
      events.subscribe(
        events.header.LANGUAGE_CHANGED
          ? events.header.LANGUAGE_CHANGED
          : 'headerLanguageChanged',
        this.handleLanguageChanged
      );

      events.subscribe(events.shop.CART_UPDATED, this.handleCartUpdated);

      this.$nuxt.$on('show-cart-toast', this.showCartToast);

      this.$nuxt.$on('show-failed-cart-toast', this.showFailedCartToast);
    },

    removeListeners() {
      events.unsubscribe(
        events.header.LANGUAGE_CHANGED
          ? events.header.LANGUAGE_CHANGED
          : 'headerLanguageChanged',
        this.handleLanguageChanged
      );
      events.unsubscribe(events.shop.CART_UPDATED, this.handleCartUpdated);
      this.$nuxt.$off();
    },

    handleLanguageChanged(languageData) {
      const {lang, market} = this.$route.params;
      if (languageData.language === 'in') {
        languageData.language = 'id';
      }
      const newRoute = this.$route.fullPath.replace(
        `/${market}/${lang}/`,
        `/${market}/${languageData.language}/`
      );
      window.location = `/catalog${newRoute}`;
    },

    handleCartUpdated(info) {
      if (info) {
        if (info.add) {
          this.showCartToast({
            amount: info.add.item.qty,
            productName: info.add.item.title
          });
        }
        if (info.modify) {
          this.showCartToast({
            amount: info.modify.item.qty,
            productName: info.modify.item.title
          });
        }
      }
    }
  }
};
