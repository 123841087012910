import Vue from 'vue';
import {
  nuskin,
  RunConfigService,
  ConfigService,
  PersonalOfferStorageService,
  ShoppingContext
} from '@nuskin/ns-util';
import {MySiteRestService} from '@nuskin/my-site-api';
import {isNullOrEmpty} from '@nuskin/ns-common-lib';
import configurationSdk from '@nuskin/configuration-sdk';

/**
 * When the route changes, if the route contains locale information, we will need to update the runConfig.
 * @param {*} options
 */
export default async function ({store, route}) {
  if (!window.nuskin) {
    window.nuskin = nuskin;
  }

  let {market} = route.params;
  let {lang} = route.params;
  market = market.toUpperCase();
  if (lang === 'id' && market === 'ID') {
    lang = 'in';
  }

  nuskin.init({
    siteConfigurationPath: undefined,
    clientId: process.env.CLIENT_ID,
    clientSecret: process.env.CLIENT_SECRET
  });

  const runConfig = RunConfigService.getRunConfig() || {};
  if (runConfig && runConfig.language) {
    if (
      runConfig.language.toUpperCase() === 'ID' &&
      runConfig.country.toUpperCase() === 'ID'
    ) {
      runConfig.language = 'in';
      RunConfigService.setRunConfig(runConfig);
    }
  }

  const env = process.env.ENV || '';

  const mysiteInfo = await MySiteRestService.initializeStaticApp(env);

  const marketConfig = ConfigService.getMarketConfig() || {};
  const shoppingContext = ShoppingContext.getShoppingContext();
  const personalOffer = PersonalOfferStorageService.getPersonalOffer();
  const configuration = await configurationSdk.getConfiguration([
    'Shopping',
    'Equinox_Markets'
  ]);

  // initialization complete
  store.commit('nuskin/set', {
    env,
    market,
    language: lang,
    runConfig,
    marketConfig,
    shoppingContext,
    personalOffer,
    mysiteInfo,
    configuration // will eventually replace marketConfig
  });

  // without these, required to refresh for runConfig changes
  const productAppService = Vue.prototype.$NsProductAppService;
  if (!isNullOrEmpty(productAppService)) {
    productAppService.setConfig(runConfig);
  }

  const appService = Vue.prototype.$NsAppService;
  if (!isNullOrEmpty(appService)) {
    appService.setConfig(runConfig);
  }

  // ready to load catalog app
  if (!store.state.nuskin.ready) {
    store.commit('nuskin/setReady');
  }
}
