var render, staticRenderFns
import script from "./CarouselItems.vue?vue&type=script&lang=js&"
export * from "./CarouselItems.vue?vue&type=script&lang=js&"
import style0 from "./CarouselItems.vue?vue&type=style&index=0&id=19264926&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19264926",
  null
  
)

export default component.exports