//
//
//
//

/**
 * WARNING: This allows intentional dynamic script injection (XSS) and can be used maliciously. Use sparingly and with that in mind.
 */
export default {
  name: 'ThirdPartyScript',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: '',
      script: ''
    };
  },
  watch: {
    value() {
      this.checkValue();
    }
  },
  mounted() {
    this.checkValue();
  },
  methods: {
    checkValue() {
      if (this.value) {
        const scriptMatch = /<script[\s\S]*?>([\s\S]*?)<\/script>/gi;

        this.content = this.value.replace(scriptMatch, '').trim();

        const scripts = scriptMatch.exec(this.value);
        if (scripts && scripts.length > 1) {
          this.script = scripts[1];
          this.addScript();
        }
      }
    },

    addScript() {
      try {
        const container = document.querySelector(
          `.${this.$style.thirdPartyScript}`
        );

        let scriptEl = container.querySelector('script');
        if (scriptEl) {
          container.removeChild(scriptEl);
          this.checkValue();
          return;
        }

        scriptEl = document.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.defer = true;
        const scriptText = document.createTextNode(this.script);
        scriptEl.appendChild(scriptText);

        container.appendChild(scriptEl);
      } catch (err) {
        this.content = '';
      }
    }
  }
};
