//
//
//
//
//
//

export default {
  name: 'GoogleAnalyticsManager',
  data() {
    return {
      loaded: false,
      interval: null
    };
  },
  computed: {
    isDev() {
      return !!this.$store.getters['nuskin/isDev'];
    },
    marketConfig() {
      return this.$store.state.nuskin.marketConfig || {};
    },
    gaUrlId() {
      const domainModifier = window.location.href.includes('mynuskin.com')
        ? 'MyNuskin'
        : '';
      return this.marketConfig[`googleUrlId${domainModifier}`] || '';
    },
    gaScript() {
      return (
        `<script>
          <!-- Google Tag Manager -->
          (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${this.gaUrlId}');
          <!-- End Google Tag Manager -->
        <` + `/script>` // doesn't like the ending script tag, eslint "protection"
      );
    },
    nextEvent() {
      return this.$store.getters['nuskin/nextGoogleAnalyticsEvent'];
    }
  },
  watch: {
    loaded() {
      this.pushEvent(this.nextEvent);
    },
    nextEvent(event) {
      this.pushEvent(event);
    }
  },
  mounted() {
    this.checkGoogleAnalyticsEnabled();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    checkGoogleAnalyticsEnabled() {
      let tries = 0;
      this.interval = setInterval(() => {
        if (window.dataLayer && !!(window.ga || {}).loaded) {
          if (this.isDev) {
            console.debug('[Catalog] (DEV) GTM Initialized.');
          }
          this.loaded = true;

          clearInterval(this.interval);
          this.interval = null;
          return;
        }

        tries++;

        if (tries > 50) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 200);
    },

    pushEvent(event) {
      // don't push to GTM if loading or event has no event name
      if (!this.loaded || !(event || {}).event) {
        return;
      }

      if (this.isDev) {
        console.debug(`[Catalog] (DEV) GTM [${event.event}]:`, event);
      }
      window.dataLayer.push(event);

      this.$store.commit('nuskin/shiftGoogleAnalyticsEvent');
    }
  }
};
