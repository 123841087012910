var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{ref:"carousel",class:_vm.$style.carousel,style:(_vm.carouselStyle)},[_c('div',{class:_vm.$style.innerCarousel},[(!_vm.hideButtons)?_c('button',{class:[
          _vm.$style.iconButton,
          _vm.$style.iconButtonLeft,
          ( _obj = {}, _obj[_vm.$style.gradient] = _vm.gradientButtons, _obj )
        ],attrs:{"data-carousel-back-button":"carousel-back-button","disabled":_vm.leftDisabled},on:{"click":function($event){return _vm.goToPrevImage()}}},[_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.iconBg] = !_vm.gradientButtons, _obj$1 )},[_c('NsIcon',{attrs:{"icon-name":"icon-angle-left-24","size":"icon-lg","color":_vm.leftDisabled ? 'rgba(0,0,0,0.34' : 'inherit'}})],1)]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.carouselWindow,on:{"touchmove":_vm.handleTouchMove,"touchend":_vm.handleTouchEnd}},[_c('div',{ref:"items",class:_vm.$style.items,style:(_vm.itemsStyle)},[_c('CarouselItems',[_vm._t("default")],2)],1)]),_vm._v(" "),(!_vm.hideButtons)?_c('button',{class:[
          _vm.$style.iconButton,
          _vm.$style.iconButtonRight,
          ( _obj$2 = {}, _obj$2[_vm.$style.gradient] = _vm.gradientButtons, _obj$2 )
        ],attrs:{"data-carousel-forward-button":"carousel-forward-button","disabled":_vm.rightDisabled},on:{"click":function($event){return _vm.goToNextImage()}}},[_c('div',{class:( _obj$3 = {}, _obj$3[_vm.$style.iconBg] = !_vm.gradientButtons, _obj$3 )},[_c('NsIcon',{attrs:{"icon-name":"icon-angle-right-24","size":"icon-lg","color":_vm.rightDisabled ? 'rgba(0,0,0,0.34' : 'inherit'}})],1)]):_vm._e()])]),_vm._v(" "),(_vm.showNavigation)?_c('div',{class:_vm.$style.navigation},_vm._l((_vm.childrenLength),function(pos){
        var _obj;
return _c('div',{key:pos,class:_vm.$style.navigationItem,attrs:{"data-testid":"carouselNavigationItem"},on:{"click":function($event){_vm.currentIndex = pos - 1}}},[_c('div',{class:[
          _vm.$style.navigationDot,
          ( _obj = {}, _obj[_vm.$style.active] = pos - 1 === _vm.currentIndex, _obj )
        ]})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }