import {ShoppingContext} from '@nuskin/ns-util';

export const state = () => ({
  ready: false,
  env: '',
  market: '',
  language: '',
  runConfig: {},
  marketConfig: {},
  gaEvents: [],
  shoppingContext: null,
  personalOffer: null,
  mysiteInfo: null,
  configuration: {}
});

export const getters = {
  isDev: (state) => {
    return (
      state.env &&
      state.env !== 'prod' &&
      (state.env !== 'test' || window.location.hostname === 'localhost')
    );
  },
  environment: (state) => {
    if (window.location.hostname === 'localhost') {
      return 'dev';
    }
    return state.env;
  },
  siteUrl: (state) => {
    return state.marketConfig.siteUrl || '';
  },
  isPersonalOffer: (state) => {
    return !!(state.personalOffer && state.personalOffer.active);
  },
  isStorefront: (state) => {
    const mysiteInfo = state.mysiteInfo || {};
    return (
      !!(mysiteInfo.sponsor || {}).sapId &&
      !!mysiteInfo.sponsorId &&
      !!mysiteInfo.subdomain
    );
  },
  isSkinConsultation: (state) => {
    return (
      (state.shoppingContext || {}).context === ShoppingContext.SKIN_CONSULT
    );
  },
  hideSubscription: (state) => {
    return (
      getters.isPersonalOffer(state) ||
      ((getters.isStorefront(state) || getters.isSkinConsultation(state)) &&
        !!(state.marketConfig.storefront || {}).mySiteHideAdr)
    );
  },
  showWholesalePricing: (state) => {
    return !!(
      state.marketConfig.showWholeSalePricing ||
      (state.personalOffer &&
        state.personalOffer.active &&
        state.personalOffer.useMemberPricing)
    );
  },
  market: (state) => {
    // don't use state.market here...
    return (state.runConfig.country || '').toUpperCase();
  },
  traditionalLocale: (state) => {
    // ex: en_US
    return `${state.language}_${getters.market(state)}`;
  },
  contentstackLocale: (state) => {
    // ex: 'en' (Common English)
    if (state.market === 'XX') {
      return state.language;
    }
    // ex: 'US-en'
    return `${state.market}-${state.language}`;
  },
  currencyLocale: (state) => {
    // ex: 'en-US'
    return `${state.language}-${getters.market(state)}`;
  },
  traditionalUrl: (state) => {
    const useMysiteHomepage =
      getters.isStorefront(state) && !getters.isPersonalOffer(state);
    const baseUrl = getters.isDev(state)
      ? useMysiteHomepage
        ? `https://mysite${state.env === 'test' ? '.test' : ''}.mynuskin.com`
        : state.marketConfig.siteUrl
      : window.location.origin;
    const traditionalLocale = getters.traditionalLocale(state);
    const aemPath = traditionalLocale === 'ja_JP' ? 'markets' : 'nuskin';
    const endPath = useMysiteHomepage
      ? `mysite/mysite-home.mysite.${state.mysiteInfo.subdomain}.html?storeId=${state.mysiteInfo.sponsorId}#home`
      : 'home.html';
    return `${baseUrl}/content/${aemPath}/${traditionalLocale}/${endPath}`;
  },
  nextGoogleAnalyticsEvent: (state) => {
    return state.gaEvents.length ? state.gaEvents[0] : null;
  }
};

export const mutations = {
  set(
    state,
    {
      env,
      market,
      language,
      runConfig,
      marketConfig,
      shoppingContext,
      personalOffer,
      mysiteInfo,
      configuration
    }
  ) {
    state.env = env || '';
    state.market = market || '';
    state.language = language || '';
    state.runConfig = runConfig || {};
    state.marketConfig = marketConfig || {};
    state.shoppingContext = shoppingContext;
    state.personalOffer = personalOffer;
    state.mysiteInfo = mysiteInfo;
    state.configuration = configuration || {}; // will eventually replace marketConfig
  },
  setShoppingContext(state, shoppingContext) {
    state.shoppingContext = shoppingContext;
  },
  pushGoogleAnalyticsEvent(state, event) {
    state.gaEvents.push(event);
  },
  shiftGoogleAnalyticsEvent(state) {
    state.gaEvents.shift();
  },
  setReady(state) {
    state.ready = true;
  }
};
