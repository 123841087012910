// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".root_Q5DlK{display:flex;flex-direction:column;width:100%}.carousel_vWcmV{position:relative;padding-top:min(100%,100vh);width:100%;overflow:hidden}.innerCarousel_HOOV9{position:absolute;top:0;left:0;right:0;bottom:0}.carouselWindow_yCD6G{position:relative;width:100%;height:100%}.items_4Oqi0{display:grid;height:100%;width:auto;background:#f6f7f7;z-index:1}.iconButton_0LlRV,.items_4Oqi0{position:absolute;top:0}.iconButton_0LlRV{bottom:0;display:flex;justify-content:center;align-items:center;width:20%;min-width:40px;max-width:68px;z-index:2}.iconButtonLeft_qG1ko{left:0}.iconButtonLeft_qG1ko.gradient_yYRnL{background:linear-gradient(90deg,rgba(0,0,0,.1),transparent)}.iconButtonLeft_qG1ko .iconBg_t63pj{padding:4px 7px 6px 3px}.iconButtonRight_0ttDd{right:0}.iconButtonRight_0ttDd.gradient_yYRnL{background:linear-gradient(90deg,transparent,rgba(0,0,0,.1))}.iconButtonRight_0ttDd .iconBg_t63pj{padding:4px 3px 6px 7px}.iconBg_t63pj{border-radius:50%;background:hsla(0,0%,100%,.5)}.navigation_dwi6B{display:flex;flex-wrap:wrap;justify-content:center;margin:5px 0}.navigationItem_hCfV\\+{margin:0 1px;padding:5px}.navigationDot_MqaHl{width:10px;height:10px;border-radius:50%;background:#bbbcbc}.navigationDot_MqaHl.active_Gyi0J{background:#272525}", ""]);
// Exports
exports.locals = {
	"root": "root_Q5DlK",
	"carousel": "carousel_vWcmV",
	"innerCarousel": "innerCarousel_HOOV9",
	"carouselWindow": "carouselWindow_yCD6G",
	"items": "items_4Oqi0",
	"iconButton": "iconButton_0LlRV",
	"iconButtonLeft": "iconButtonLeft_qG1ko",
	"gradient": "gradient_yYRnL",
	"iconBg": "iconBg_t63pj",
	"iconButtonRight": "iconButtonRight_0ttDd",
	"navigation": "navigation_dwi6B",
	"navigationItem": "navigationItem_hCfV+",
	"navigationDot": "navigationDot_MqaHl",
	"active": "active_Gyi0J"
};
module.exports = exports;
