// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:\"\";content:none}table{border-collapse:collapse;border-spacing:0}.root_tcu6T *{font-family:\"proxima-nova\",\"OpenSans\",\"Pridi\",\"NotoSans\",\"NotoSansSC\",\"NotoSansHebrew\",\"NotoSansJapanese\",\"Hiragino Kaku Gothic Pro\",\"Yu Gothic Medium\",\"Yu Gothic\",Meiryo,\"source-han-sans-japanese\",\"source-han-sans-korean\",\"source-han-sans-simplified-c\",Helvetica,Arial,sans-serif;box-sizing:border-box}.header_0qNP8{height:70px;background:#fff;border-bottom:1px solid #252525}.footer_Q7mFR,.header_0qNP8{padding:20px;width:100%}.footer_Q7mFR{height:300px;background:#ddd}.nuxtLink_7HXqX{margin:0 15px}", ""]);
// Exports
exports.locals = {
	"root": "root_tcu6T",
	"header": "header_0qNP8",
	"footer": "footer_Q7mFR",
	"nuxtLink": "nuxtLink_7HXqX"
};
module.exports = exports;
